import { RedoOutlined } from "@ant-design/icons";
import { Button, Card, Row, Table, Tag, Col } from "antd";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import dayjs from "dayjs";
import Loading from "components/shared-components/Loading";
import { UserApi } from "api/UserApi";
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
import { APP_PREFIX_PATH } from "config/AppConfig";

const initial_filter = {
  keyword: null,
  page: 1,
  limit: 10,
};

export default function Customers() {
  // Table columns
  const columns = [
    {
      title: t("Market name"),
      width: 100,
      dataIndex: "market_name",
      key: "market_name",
      render: (record, _) => {
        return (
          <Link to={`${APP_PREFIX_PATH}/user/${_.id}`}>{_.market_name}</Link>
        );
      },
    },
    {
      title: t("Phone number"),
      width: 100,
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: t("Address"),
      width: 100,
      dataIndex: "address",
      key: "address",
    },
    {
      title: t("Total"),
      width: 100,
      dataIndex: "total",
      key: "total",
    },
    {
      title: t("Cashback"),
      width: 100,
      dataIndex: "bonus",
      key: "bonus",
    },
    {
      title: t("Telegram"),
      width: 100,
      dataIndex: "chat_id",
      key: "chat_id",
      render: (record, _) => {
        return <a href={`https://t.me/${_.phone_number}`}>Telegram</a>;
      },
    },
    {
      title: t("Status"),
      width: 100,
      dataIndex: "status",
      key: "status",
      render: (record) => {
        if (record === "active") {
          return <Tag color={"green"}>{t("Active")}</Tag>;
        }
        if (record === "inactive") {
          return <Tag color={"red"}>{t("Inactive")}</Tag>;
        }
      },
    },
    {
      title: t("CreatedAt"),
      width: 100,
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (record) => {
        return dayjs(record).format("DD.MM.YYYY, HH:MM");
      },
    },
  ];
  // States
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(initial_filter);

  // Get application types
  useEffect(() => {
    fetch(filter);
  }, []);

  function fetch(filter) {
    setLoading(true);
    UserApi.get(filter)
      .then((res) => {
        setData(res);
        setFilter({
          ...filter,
          total: res.total,
          page: res.page,
        });
        setLoading(false);
      })
      .catch((err) => {
        MessagesService.error(err.message);
        setLoading(false);
      });
  }
  // Render
  return loading ? (
    <Loading />
  ) : (
    <>
      <Card>
        <h4 className="font-semibold text-base mb-3">{t("Customers")}</h4>
        <Row className="justify-between items-center">
          <Search
            placeholder={t("Search by phone number or market name")}
            type="text"
            className="w-3/5"
            size="large"
            enterButton={t("Search")}
            defaultValue={filter.keyword}
            onSearch={(value) => {
              setFilter({ ...filter, keyword: value });
              fetch({ ...filter, keyword: value });
            }}
          />
          <Button
            icon={<RedoOutlined />}
            size="large"
            onClick={() => fetch(filter)}
          >
            <span>{t("Refresh")}</span>
          </Button>
        </Row>
      </Card>

      <Card>
        <Table
          columns={columns}
          loading={loading}
          onChange={(p, _, sort) => {
            const f = {
              ...filter,
              page: p.current,
              limit: p.pageSize,
            };
            setFilter(f);
            fetch(f);
          }}
          pagination={{
            current: filter?.page,
            total: data?.total,
            pageSize: filter?.limit,
          }}
          dataSource={data?.items}
        />
      </Card>
    </>
  );
}
