import React, { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Row, Table, Col, Popconfirm, Select } from "antd";
import { t } from "i18n";
import Search from "antd/lib/input/Search";
import { MessagesService } from "services/Messages";
import dayjs from "dayjs";
import Loading from "components/shared-components/Loading";
import { EventUsersApi } from "api/EventUsersApi";
import UserModal from "./UserModal";

const initial_filter = {
  keyword: null,
  product_type: "",
  ageFrom: null,
  ageTo: null,
  page: 1,
  limit: 10,
};

export default function EventUsers() {
  // Table columns
  const columns = [
    {
      title: t("Full name"),
      width: 100,
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: t("age"),
      width: 100,
      dataIndex: "age",
      key: "age",
    },
    {
      title: t("Phone number"),
      width: 100,
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: t("Address"),
      width: 100,
      dataIndex: "address",
      key: "address",
    },
    {
      title: t("Telegram"),
      width: 100,
      dataIndex: "phone_number",
      key: "phone_number",
      render: (record) => {
        return <a href={`https://t.me/${record}`}>Telegram</a>;
      },
    },
    {
      title: t("CreatedAt"),
      width: 100,
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (record) => {
        return dayjs(record).format("DD.MM.YYYY");
      },
    },
    {
      title: t("Action"),
      key: "operation",
      width: 120,
      render: (record, _) => {
        return (
          <div className="gap-1">
            <Button
              type="primary"
              shape="round"
              ghost
              size="middle"
              onClick={() => {
                setState({
                  ...state,
                  isOpen: true,
                  content: _,
                });
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t("Are you sure to delete this user?")}
              okText={t("Yes")}
              cancelText={t("No")}
              type="danger"
              shape="round"
              size="middle"
              onConfirm={() => {
                onRemove(record.id);
              }}
            >
              <Button
                type="danger"
                shape="round"
                ghost
                size="middle"
                style={{ margin: "10px 0 0 10px" }}
              >
                <DeleteOutlined style={{ color: "red" }} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  // States
  const [data, setData] = useState();
  const [filter, setFilter] = useState(initial_filter);
  const [state, setState] = useState({
    content: null,
    isOpen: false,
    loading: false,
  });

  async function fetch(filter) {
    setState({ ...state, loading: true });
    try {
      const res = await EventUsersApi.get(filter);
      setData(res);
      setFilter({
        ...filter,
        total: res.total,
        page: res.page,
      });
    } catch (err) {
      MessagesService.error(err.message);
    }
    setState({ ...state, loading: false });
  }

  const onSelect = (value, key) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [key]: value,
    }));
  };

  const onRemove = async (id) => {
    try {
      await EventUsersApi.delete(id);
      setData((prevState) => ({
        ...prevState,
        items: data.items.filter((item) => item.id !== id),
      }));
      MessagesService.success(t("Promocode removed"));
    } catch (error) {
      MessagesService.error(t(error.message));
    }
  };

  useEffect(() => {
    fetch(filter);
  }, []);

  return state.loading ? (
    <Loading />
  ) : (
    <>
      <Card>
        <h4>{t("Customers")}</h4>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
          className="d-flex align-items-start"
        >
          <Col span={8}>
            <Row className="align-items-center justify-content-between">
              <Col span={11}>
                <Select
                  size="middle"
                  showSearch
                  style={{ width: "100%" }}
                  placeholder={t("age_from")}
                  defaultValue={filter.ageFrom}
                  onSelect={(e) => onSelect(e, "ageFrom")}
                  options={Array.from({ length: 71 }, (_, i) => ({
                    label: i + 10,
                    value: i + 10,
                  }))}
                />
              </Col>
              -
              <Col span={11}>
                <Select
                  size="middle"
                  showSearch
                  defaultValue={filter.ageTo}
                  style={{ width: "100%" }}
                  placeholder={t("age_to")}
                  onSelect={(e) => onSelect(e, "ageTo")}
                  options={Array.from({ length: 71 }, (_, i) => ({
                    label: i + 10,
                    value: i + 10,
                  }))}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Search
              placeholder={t("search_full_name")}
              type="text"
              size="middle"
              enterButton={t("Filter")}
              defaultValue={filter.keyword}
              style={{ borderRadius: "2rem", display: "block" }}
              onSearch={(value) => {
                setFilter({ ...filter, keyword: value });
                fetch({ ...filter, keyword: value });
              }}
            />
          </Col>
          <Col span={4}>
            <Button
              className="w-100"
              size="middle"
              onClick={() => fetch(initial_filter)}
            >
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card>
        <Row align="middle">
          <span>
            {t("total_count")}:{data?.total}
          </span>
          <Button
            className="d-block ml-auto mb-2"
            onClick={() => setState({ ...state, isOpen: true })}
          >
            <PlusOutlined />
            {t("Add")}
          </Button>
        </Row>
        <Table
          columns={columns}
          loading={state.loading}
          rowKey={(record) => record.id}
          onChange={(p, _) => {
            const f = {
              ...filter,
              page: p.current,
              limit: p.pageSize,
            };
            setFilter(f);
            fetch(f);
          }}
          pagination={{
            current: filter?.page,
            total: data?.total,
            pageSize: filter?.limit,
          }}
          dataSource={data?.items}
        />
      </Card>
      {state.isOpen && (
        <UserModal open={state} setOpen={setState} setData={setData} />
      )}
    </>
  );
}
