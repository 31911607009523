import { APP_PREFIX_PATH } from "../config/AppConfig";
import { t } from "../i18n";

export const roles = {
  admin: "admin",
  moderator: "moderator",
};

const dashBoardNavTree = {
  key: "home5",
  path: `${APP_PREFIX_PATH}/`,
  title: `${t("Dashboard")}`,
  icon: "SearchOutlined",
  breadcrumb: false,
  submenu: [
    {
      key: "home2",
      path: `${APP_PREFIX_PATH}/home`,
      title: `${t("Dashboard")}`,
      icon: "HomeOutlined",
      submenu: [],
      other: true,
    },
    {
      key: "application",
      path: `${APP_PREFIX_PATH}/application`,
      title: `${t("Application")}`,
      icon: "UnorderedListOutlined",
      submenu: [],
      other: true,
    },
    {
      key: "category",
      path: `${APP_PREFIX_PATH}/category`,
      title: `${t("Category")}`,
      icon: "AppstoreOutlined",
      submenu: [],
      other: true,
    },
    {
      key: "product",
      path: `${APP_PREFIX_PATH}/product`,
      title: `${t("Product")}`,
      icon: "InboxOutlined",
      submenu: [],
      other: true,
    },
    {
      key: "customers",
      path: `${APP_PREFIX_PATH}/customer`,
      title: `${t("Customers")}`,
      icon: "UserOutlined",
      submenu: [],
      other: true,
    },
    // {
    //   key: "promocodes",
    //   path: `${APP_PREFIX_PATH}/promocode`,
    //   title: `${t("Event")}`,
    //   icon: "ProfileOutlined",
    //   submenu: [],
    //   other: true,
    // },
    // {
    //   key: "event_users",
    //   path: `${APP_PREFIX_PATH}/event-users`,
    //   title: `${t("event_users")}`,
    //   icon: "TeamOutlined",
    //   submenu: [],
    //   other: true,
    // },
  ],
};

export const permissions = {
  READ_SEARCH_PANEL: "READ_SEARCH_PANEL",
};

export function generateNavigationConfig() {
  return dashBoardNavTree.submenu;
}

const navigationConfig = [dashBoardNavTree];

export default navigationConfig;
