import React from "react";
import { Layout, Menu, Button, Select } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import AppViews from "../../views/app-views";
import { generateNavigationConfig } from "../../config/NavigationConfig";
import Icon from "../../components/util-components/Icon";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { changeLanguage, getLang, t } from "../../i18n";
import { signOut } from "../../redux/actions/Auth";
import { useHistory } from "react-router-dom";

const { Header, Content, Footer, Sider } = Layout;

export const AppLayout = () => {
  const history = useHistory();

  return (
    <Layout hasSider>
      <Sider collapsible className="min-h-screen" width={250}>
        <div className="w-full bg-white flex items-center justify-center h-[62px]">
          <img className={`max-h-[50px]`} src={logo} alt="logo" />
        </div>
        <Menu theme="dark" mode="inline" defaultOpenKeys={["sub1"]}>
          {generateNavigationConfig().map((item, i) => (
            <Menu.Item key={i}>
              <Icon type={item.icon} />
              <span>{item.title}</span>
              <Link to={item.path} />
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Header className="bg-white relative flex justify-end items-center gap-2">
          <Select
            onChange={changeLanguage}
            defaultValue={getLang()}
            options={[
              {
                value: "uz",
                label: "O'zbekcha",
              },
              {
                value: "ru",
                label: "Русский",
              },
              {
                value: "en",
                label: "English",
              },
            ]}
          />
          <Button
            icon={<LogoutOutlined />}
            onClick={() => {
              signOut();
              history.push("/auth/login");
            }}
          >
            {t("Exit")}
          </Button>
        </Header>
        <Content className="mx-[24px] my-[16px]">
          <AppViews />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Gumstar.uz ©{new Date().getFullYear()} Created by <b>Noortech</b>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
