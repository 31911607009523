import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Image,
  Modal,
  Popconfirm,
  Row,
  Table,
  Tag,
} from "antd";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import initial_filter from "constants/InitialFilter";
import { ProductApi } from "api/ProductApi";
import { mainUrl } from "api/mainApiInstence";
import { FormComponent } from "./components/FormComponent";
import { formatCurrency } from "utils/formatCurrency";
import { CategoryApi } from "api/CategoryApi";

export default function Product() {
  // Table columns
  const columns = [
    {
      title: t("Id"),
      width: 100,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (record, _) => {
        return (
          <Button
            type="link"
            onClick={() => {
              navigator.clipboard.writeText(record);
              MessagesService.success(t("Copied"));
            }}
          >
            ID
            <CopyOutlined />
          </Button>
        );
      },
    },
    {
      title: t("Name"),
      width: 100,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: t("Price"),
      width: 100,
      dataIndex: "price",
      key: "price",
      fixed: "left",
      render: (record, _) => {
        return <span>{formatCurrency(record)}</span>;
      },
    },
    {
      title: t("Status"),
      width: 70,
      dataIndex: "status",
      key: "status",
      render: (record, _) => {
        return (
          <Tag color={record === "active" ? "#87d068" : "#f50"}>
            {t(record)}
          </Tag>
        );
      },
    },
    {
      title: t("Photo"),
      width: 130,
      dataIndex: "photo",
      key: "photo",
      render: (record, _) => {
        return (
          <Image
          className="rounded-lg"
            src={mainUrl() + "/files/" + record}
            style={{
              height: "150px",
              objectFit: "contain",
            }}
          />
        );
      },
    },
    {
      title: t("Action"),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (record) => {
        return (
          <div gap={2}>
            <Button
              type="primary"
              ghost
              size="middle"
              onClick={() => {
                form.setFieldsValue(record);
                setFileList({
                  photo: [
                    {
                      url: mainUrl() + "/files/" + record.photo,
                    },
                  ],
                });
                setEdit(true);
              }}
            >
              {t("Edit")}
              <EditOutlined />
            </Button>

            <Popconfirm
              title={t("Are you sure to delete this product?")}
              okText={t("Yes")}
              okType="danger"
              cancelText={t("No")}
              size="middle"
              icon={<DeleteOutlined style={{ color: "red" }} />}
              onConfirm={() => {
                onRemove(record.id);
              }}
            >
              <Button
                icon={<DeleteOutlined />}
                danger
                ghost
                size="middle"
                style={{ marginTop: 10 }}
              >
                {t("Delete")}
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  // States
  const [data, setData] = useState();
  const [categories, setCategories] = useState();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [fileList, setFileList] = useState({
    photo: [],
  });
  const [form] = Form.useForm();

  // Get portfolios
  useEffect(() => {
    setLoading(true);
    setBtnLoading(true);
    CategoryApi.get().then((res) => {
      if (res) {
        setCategories(res.categories);
      }
    });
    ProductApi.get(initial_filter)
      .then((res) => {
        setData(res.product);
        setBtnLoading(false);
        setLoading(false);
      })
      .catch((err) => {
        MessagesService.error(err.message);
        setLoading(false);
        setBtnLoading(false);
      });
  }, []);

  // Post Pacht Delete course functions
  const onFinish = () => {
    setBtnLoading(true);
    const formData = new FormData();
    formData.append("name", form.getFieldValue().name);
    formData.append("name_ru", form.getFieldValue().name_ru);
    formData.append("price", form.getFieldValue().price);
    formData.append("category_id", form.getFieldValue().category_id);
    formData.append("description", form.getFieldValue().description);
    formData.append("description_ru", form.getFieldValue().description_ru);
    formData.append("photo", form.getFieldValue().photo.file.originFileObj);
    formData.append("status", form.getFieldValue().status);
    ProductApi.create(formData).then((res) => {
      if (res) {
        setData([...data, res.product]);
        MessagesService.success(t("Product created"));
        setOpen(false);
        form.resetFields();
        setBtnLoading(false);
      }
    });
  };
  
  const onEdit = () => {
    try {
      const formData = new FormData();
      setBtnLoading(true);
      formData.append("name", form.getFieldValue().name);
      formData.append("name_ru", form.getFieldValue().name_ru);
      formData.append("price", form.getFieldValue().price);
      formData.append("category_id", form.getFieldValue().category_id);
      formData.append("status", form.getFieldValue().status);
      formData.append("description", form.getFieldValue().description);
      formData.append("description_ru", form.getFieldValue().description_ru);
      if (form.getFieldValue().photo?.file?.originFileObj) {
        formData.append("photo", form.getFieldValue().photo.file.originFileObj);
      }

      ProductApi.edit(form.getFieldValue().id, formData).then((res) => {
        if (res) {
          console.log(res);
          MessagesService.success(t("Product updated"));
          setEdit(false);
          setData(
            data.map((item) => {
              if (item.id === res.product.id) {
                return res.product;
              }
              return item;
            })
          );
          form.resetFields();
          setFileList({ photo: [] });
          setBtnLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const onRemove = (id) => {
    ProductApi.delete(id).then((res) => {
      if (res) {
        setData(data.filter((item) => item.id !== id));
        MessagesService.success(t("Product removed"));
      }
    });
  };

  // Render
  return (
    <>
      <Card>
        <Row className="justify-between items-center">
          <h4 className="font-semibold text-base">{t("Product")}</h4>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              setFileList({ photo: [] });
              setOpen(true);
              form.resetFields();
            }}
          >
            {t("Create product")}
          </Button>
        </Row>
      </Card>
      <Card>
        <Table
          columns={columns}
          dataSource={data}
          // scroll={{
          //   x: 2000,
          // }}
          loading={loading}
          // onChange={(p, _, sort) => {
          //   const f = {
          //     ...filter,
          //     page: p.current,
          //     limit: p.pageSize,
          //   };
          //   setFilter(f);
          //   fetch(f);
          // }}
          pagination={
            {
              // current: filter.page,
              // total: data?.total,
              // pageSize: filter.limit,
            }
          }
        />
      </Card>
      <Modal
        title={t("Create product")}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
        footer={null}
      >
        <FormComponent
          type="create"
          fileList={fileList}
          setFileList={setFileList}
          form={form}
          onFinish={onFinish}
          btnLoading={btnLoading}
          categories={categories}
        ></FormComponent>
      </Modal>
      <Modal
        title={"Edit product"}
        centered
        open={edit}
        onOk={() => setEdit(false)}
        onCancel={() => setEdit(false)}
        footer={null}
        width={800}
      >
        <FormComponent
          type="edit"
          fileList={fileList}
          setFileList={setFileList}
          form={form}
          onFinish={onEdit}
          btnLoading={btnLoading}
          categories={categories}
        ></FormComponent>
      </Modal>
    </>
  );
}

export const changeVideoLink = (link) => {
  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }
  document.querySelector(".iFrameElement").src =
    "https://www.youtube.com/embed/" +
    getId(link) +
    "?enablejsapi=1&version=3&playerapiid=ytplayer";
};
