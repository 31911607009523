import { DatePicker, Space } from "antd";
import { ApplicationApi } from "api/ApplicationApi";
import dayjs from "dayjs";
import { t } from "i18n";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "utils/formatCurrency";

const initial_date = moment().startOf("day").format("YYYY-MM-DD");

export default function Dashboard() {
  const [data, setData] = useState();
  const [date, setDate] = useState(initial_date);

  useEffect(() => {
    ApplicationApi.getStat({
      date,
    })
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [date]);

  const onChange = (_, dateString) => {
    setDate(dateString);
  };

  return (
    <div>
      <Space className="mb-4 gap-1" direction="vertical">
        <span>{t("Date")}</span>
        <DatePicker
          format="YYYY-MM-DD"
          onChange={onChange}
          defaultValue={dayjs(date)}
          placeholder={t("Date")}
        />
      </Space>
      <div className="rounded-md overflow-hidden max-w-[500px] bg-[#fff]">
        <div className="bg-[#002140] text-white p-[10px] text-center">
          {t("The total amount of applications for ") +
            dayjs(date).format("D MMM")}
        </div>
        <div className="text-black text-center py-10">
          <h2 className="text-[32px] text-black">
            {formatCurrency(data?.total)}
          </h2>
          <span>
            {t("Sale on ")}
            {dayjs(date).format("D MMM")}
          </span>
        </div>
      </div>
    </div>
  );
}
