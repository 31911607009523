import { Button, Form, Input, InputNumber, Select, Upload } from "antd";
import { t } from "i18n";
import React from "react";
import ReactQuill from "react-quill";
import { MessagesService } from "services/Messages";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

export function FormComponent(props) {
  function ValidateFile(file) {
    const isLargeFile = file?.size && file?.size >= 5 * 1024 * 1024;
    if (isLargeFile) {
      throw new Error(`${file.name} ${t("Image size should not exceed 5mb.")}`);
    }
  }
  const onChange = ({ newFileList, key }) => {
    ValidateFile(newFileList[0]);
    if (newFileList[0]?.status === "done") {
      MessagesService.success("File uploaded successfully");
    }
    const obj = Object.create(null);
    obj[key] = newFileList;
    props.setFileList({ ...props.fileList, ...obj });
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <Form name={"Portfolio"} form={props.form} onFinish={props.onFinish}>
      {/* Category */}
      <Form.Item
        label={t("Category")}
        name="category_id"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        rules={[
          {
            required: true,
            message: "Please input category!",
          },
        ]}
      >
        <Select
          placeholder={t("Category")}
          options={
            props.categories.map((category) => ({
              value: category.id,
              label: category.name,
            })) || []
          }
        />
      </Form.Item>
      {/* Name */}
      <Form.Item
        label={t("Name")}
        s
        name="name"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        rules={[
          {
            required: true,
            message: "Please input name!",
          },
        ]}
      >
        <Input placeholder={t("Name")} />
      </Form.Item>

      {/* Name ru */}
      <Form.Item
        label={t("Name ru")}
        name="name_ru"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        rules={[
          {
            required: true,
            message: "Please input name ru!",
          },
        ]}
      >
        <Input placeholder={t("Name ru")} />
      </Form.Item>

      {/* Description */}
      <Form.Item
        label={t("Content")}
        name="description"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        rules={[
          {
            required: true,
            message: "Please input name!",
          },
        ]}
      >
        <ReactQuill theme="snow" modules={modules} />
      </Form.Item>

      {/* Description */}
      <Form.Item
        label={t("Content ru")}
        name="description_ru"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        rules={[
          {
            required: true,
            message: "Please input description_ru!",
          },
        ]}
      >
        <ReactQuill theme="snow" modules={modules} />
      </Form.Item>

      {/* Price */}
      <Form.Item
        label={t("Price")}
        name="price"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        rules={[
          {
            required: true,
            message: "Please input price!",
          },
        ]}
      >
        <InputNumber
          placeholder={t("Price")}
          style={{ width: "100%" }}
          min={0}
        />
      </Form.Item>

      {/* photo */}
      <Form.Item
        label={t("Photo")}
        name="photo"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        rules={[
          {
            required: true,
            message: "Please input photo!",
          },
        ]}
      >
        <Upload
          accept="image/png, image/jpeg, image/svg, image/webp"
          listType="picture-card"
          fileList={props.fileList?.photo || []}
          onPreview={onPreview}
          showUploadList={true}
          customRequest={({ file, onSuccess }) => {
            onSuccess("ok");
          }}
          onChange={(e) =>
            onChange({
              newFileList: e.fileList,
              key: "photo",
            })
          }
          onRemove={() => {
            props.setFileList({ ...props.fileList, photo: [] });
          }}
        >
          {props.fileList?.photo.length < 1 && t("+ Upload")}
        </Upload>
      </Form.Item>

      {/* Status */}
      <Form.Item
        label={t("Status")}
        name="status"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        rules={[
          {
            required: true,
            message: "Please input status!",
          },
        ]}
      >
        <Select
          placeholder={t("Status")}
          options={[
            {
              value: "active",
              label: t("Active"),
            },
            {
              value: "inactive",
              label: t("Inactive"),
            },
          ]}
        />
      </Form.Item>

      <Form.Item className="flex justify-end mb-0">
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={props.btnLoading}
        >
          {props.type === "create" ? t("Create") : t("Update")}
        </Button>
      </Form.Item>
    </Form>
  );
}
