import { RedoOutlined } from "@ant-design/icons";
import { Button, Card, Row, Table, Col, Input, Select } from "antd";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import dayjs from "dayjs";
import Loading from "components/shared-components/Loading";
import { UserApi } from "api/UserApi";
import { useParams } from "react-router-dom";
import { formatCurrency } from "utils/formatCurrency";
import { StatusSwitcher } from "constants/OrderStatus";

const initial_filter = {
  keyword: null,
  page: 1,
  limit: 10,
};

const columns = [
  {
    title: t("Status"),
    width: 100,
    dataIndex: "status",
    key: "status",
    render: (record) => StatusSwitcher(record),
  },
  {
    title: t("Price"),
    width: 100,
    dataIndex: "total",
    key: "total",
    render: (record) => {
      return formatCurrency(record);
    },
  },
  {
    title: t("Cashback"),
    width: 100,
    dataIndex: "bonus",
    key: "bonus",
    render: (record, _) => {
      if (_.order_type === "bonus" || _.status !== "delivered") {
        return 0;
      }
      return formatCurrency(
        _.total > 1000000
          ? Math.round(_.total * 0.08)
          : Math.round(_.total * 0.03)
      );
    },
  },
  {
    title: t("Payment"),
    width: 100,
    dataIndex: "order_type",
    key: "order_type",
    render: (record) => {
      return t(record);
    },
  },
  {
    title: t("CreatedAt"),
    width: 100,
    dataIndex: "createdAt",
    key: "createdAt",
    // sorter: (a, b) => a.createdAt - b.createdAt,
    render: (record) => {
      return dayjs(record).format("DD.MM.YYYY, HH:MM");
    },
  },
];

export default function UserOrders() {
  const { id } = useParams();
  // States
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(initial_filter);
  // Get application types
  useEffect(() => {
    fetch(filter);
  }, []);

  async function fetch(filter) {
    setLoading(true);
    try {
      const user = await UserApi.getById(id);
      console.log(user);
    } catch (error) {}

    UserApi.getOrdersById(id, filter)
      .then((res) => {
        setData(res);
        setFilter({
          ...filter,
          total: res.total,
          page: res.page,
        });
        setLoading(false);
      })
      .catch((err) => {
        MessagesService.error(err.message);
        setLoading(false);
      });
  }

  const changeChash = async () => {
    const bonus = document.getElementById("bonus").value;
    try {
      await UserApi.updateCashById({ id, bonus: bonus });
      MessagesService.success("Updated!");
    } catch (error) {
      MessagesService.error(error.message);
    }
  };
  // Render
  return loading ? (
    <Loading />
  ) : (
    <>
      <Card>
        <Row className="flex items-center">
          <h4 className="text-[16px]">
            {t("Orders")}:{" "}
            <span className="font-semibold">{data?.user.market_name}</span>
          </h4>
          <Col className="flex items-center ml-auto">
            <h5 className="m-0">{t("Cashback")}:&nbsp;</h5>
            <Input id="bonus" name="bonus" defaultValue={data?.user.bonus} />
            <Button
              type="primary"
              style={{ margin: "0 3px" }}
              onClick={changeChash}
            >
              {t("Change")}
            </Button>
          </Col>
          <Col>
            <Button onClick={() => fetch(filter)}>
              <RedoOutlined />
              <span>{t("Refresh")}</span>
            </Button>
          </Col>
        </Row>
      </Card>
      <Card>
        <Row className="flex justify-between items-center mb-3">
          <h5 className="m-0">
            {t("Total")}: {formatCurrency(data?.user.total)}
          </h5>
          <h5 className="m-0">
            {console.log(data?.userBonusSum)}
            {t("Total Cashback")}: {formatCurrency(data?.userBonusSum)}
          </h5>
          <h5 className="m-0">
            {t("Used Cashback")}:{" "}
            {formatCurrency(data?.userBonusSum - data?.user.bonus)}
          </h5>
          <Col md={5}>
            <Select
              style={{ width: "100%" }}
              value={filter.keyword}
              onChange={(value) => {
                setFilter({ ...filter, keyword: value });
                fetch({ ...filter, keyword: value });
              }}
            >
              <Select.Option value={null}>{t("All")}</Select.Option>
              <Select.Option value="simple">{t("simple")}</Select.Option>
              <Select.Option value="bonus">{t("bonus")}</Select.Option>
            </Select>
          </Col>
        </Row>

        <Table
          columns={columns}
          loading={loading}
          rowKey={(record) => record.id}
          expandable={{
            expandedRowRender: (record) => {
              const columns = [
                {
                  title: t("Product name"),
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: t("Category name"),
                  dataIndex: "category",
                  key: "category",
                  render: (record, _) => {
                    return _?.product?.category?.name;
                  },
                },
                {
                  title: t("Price"),
                  dataIndex: "price",
                  key: "price",
                  render: (record) => {
                    return formatCurrency(record);
                  },
                },
                {
                  title: t("Count"),
                  dataIndex: "count",
                  key: "count",
                },
              ];
              return (
                <Table
                  style={{ marginBottom: "20px" }}
                  columns={columns}
                  pagination={false}
                  dataSource={record?.order_items}
                />
              );
            },
          }}
          onChange={(p, _, sort) => {
            const f = {
              ...filter,
              page: p.current,
              limit: p.pageSize,
            };
            setFilter(f);
            fetch(f);
          }}
          pagination={{
            current: filter?.page,
            total: data?.total,
            pageSize: filter?.limit,
          }}
          dataSource={data?.items}
        />
      </Card>
    </>
  );
}
